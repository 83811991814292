import { Suspense, lazy } from 'react';
const Component = lazy(
  () =>
    import(
      /* webpackChunkName: Will-this-work */
      './App.tsx'
    ),
);

const LazyApp = () => (
  <Suspense fallback={<div>Loading...</div>}>
    <Component />
  </Suspense>
);

export default LazyApp;
